import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, Link, withRouter } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { NotificationContainer } from 'react-light-notifications';

import Home from './modules/home';
import Articles from './modules/articles';
import ArticlesLatest from './modules/articles/latest';
import ContactUs from './modules/contactUs';
import Login from './modules/auth/login';
import Register from './modules/auth/register';
import ForgotPassword from './modules/auth/forgotPassword';
import ResetPassword from './modules/auth/resetPassword';
import Categories from './modules/categories';
import Tags from './modules/tags';
import About from './modules/about';
import CareerForm from './modules/career';
import Beriklan from './modules/beriklan';
import PrivacyPolicy from './modules/privacyPolicy';
import Search from './modules/search';
import Author from './modules/author';
import Polling from './modules/polling';
import PollingVote from './modules/polling/vote';
// start admin router
import Dashboard from './modules/admin/dashboard';
import CareerPosition from './modules/admin/careerPosition';
import Comment from './modules/admin/comments';
import ActivityLog from './modules/admin/activityLogs';
import DetailActivityLog from './modules/admin/activityLogs/detail';
import Role from './modules/admin/roles';
import User from './modules/admin/users';
import UserCreate from './modules/admin/users/create';
import UserEdit from './modules/admin/users/edit';
import PollingDashboard from './modules/admin/polling';
import PollingCreate from './modules/admin/polling/create';
import PollingEdit from './modules/admin/polling/edit';
import Ads from './modules/admin/ads';
import Tag from './modules/admin/tags';
import Category from './modules/admin/categories';
import Media from './modules/admin/medias';
import PostDashboard from './modules/admin/posts';
import PostCreate from './modules/admin/posts/create';
import PostEdit from './modules/admin/posts/edit';
import PreviewPost from './modules/admin/posts/preview';
import NitizenJurnalism from './modules/admin/nitizenJurnalism';
import Profile from './modules/admin/profile';
import ReportPost from './modules/admin/report/post';
import ReportContact from './modules/admin/report/contact';
import ReportCareer from './modules/admin/report/career';
import Test from './modules/test';
// end admin router
import NotFound from './NotFound';

import Header from './components/Header';
import TopHeader from './components/TopHeader';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Trending from './components/Trending';
import JadwalImsak from './components/JadwalImsak';

import { scriptsMaster } from './utils/scripts';
import PrivateRoute from './utils/privateRoute';

import Api from './services/api';

import { IsSSR } from './helpers/isSSR';
import Covid19 from './components/Covid19';

import withPageView from './helpers/withPageView';
import PedomanPemberitaanMediaSiber from './modules/PedomanPemberitaanMediaSiber';
import SopPerlindunganJurnalis from './modules/SopPerlindunganJurnalis';
import Disclaimer from './modules/Disclaimer';
import AlamatRedaksi from './modules/AlamatRedaksi';
import redaksi from './modules/redaksi';
import TagsHeader from './components/Tags';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingAdv: false,
      datasAdv: [],
      loadingHeadline: true,
      datasHeadline: [],
      datasHeadlineGroup: [],
      floating: false,
      menusManual: []
    };
    this.isSSR = IsSSR();
  }

  componentDidMount() {
    this.fetchAdv();
    this.fetchHeadline();
    this.fetchNavigation();
    // if (!this.isSSR)
    //     if (window.outerWidth < 480)
    //         this.fetchHeadline()
    if (!this.isSSR && window.outerWidth < 480) {
      this.setState({ floating: true });
    }

    setTimeout(() => {
      this.bundleInternal();
    }, 900);
  }

  bundleInternal() {
    scriptsMaster(() => {
      console.log('[scriptsMaster.js]');
    });
  }

  fetchAdv() {
    this.setState({ loadingAdv: true }, () => {
      const params = `page=1&limit=20&keyword=`;
      const route = `/ad/index?${params}`;
      Api.get(route, null)
        .then((response) => {
          var lists = {};
          if (response.data.length > 0) {
            response.data.map((item) => {
              lists[item.position] = item;
            });
          }
          this.setState({ datasAdv: lists });
          this.setState({ loadingAdv: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loadingAdv: false });
        });
    });
  }

  fetchNavigation() {
    this.setState({ loadingNavigation: true }, () => {
      // const params = `page=1&limit=10&keyword=`;
      // const route = `/category/index?${params}`;
      const route = '/category/getCategoriesByOrder';
      Api.get(route, null)
        .then((response) => {
          this.setState({ menusManual: response.data });
          this.setState({ loadingNavigation: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loadingNavigation: false });
        });
    });
  }

  fetchHeadline() {
    console.log('fetchHeadline loaded - App.JS');
    this.setState({ loadingHeadline: true }, () => {
      const params = `page=1&limit=30&keyword=&state_id=3&schedule=0`;
      const route = `/post/is_headline?${params}`;
      Api.get(route, null)
        .then((response) => {
          console.log('fetchHeadline loaded - App.JS:', response);
          var datasGroup = this.groupArr(response.data, 10);
          this.setState({ datasHeadlineGroup: datasGroup });
          this.setState({ datasHeadline: response.data });
          this.setState({ loadingHeadline: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loadingHeadline: false });
        });
    });
  }

  checkPageHome() {
    if (window.location.pathname == '/') {
      window.location.reload();
    }
  }

  groupArr(data, n) {
    var group = [];
    for (var i = 0, j = 0; i < data.length; i++) {
      if (i >= n && i % n === 0) j++;
      group[j] = group[j] || [];
      group[j].push(data[i]);
    }
    return group;
  }

  render() {
    const { loadingAdv, datasAdv, loadingHeadline, datasHeadlineGroup } = this.state;

    if (typeof window === 'undefined') {
      global.window = {};
    }

    // if (loadingHeadline) return <div style={{ padding: 16, fontWeight: 'bold', margin: '30%', textAlign: 'center' }}>Loading...</div>
    return (
      <div>
        <div
          style={{
            width: '100%',
            height: 'auto',
            textAlign: 'right',
            position: 'fixed',
            zIndex: '999999',
            top: '50px',
            right: '16px'
          }}
        >
          <NotificationContainer />
        </div>
        <Helmet>
          <title>{`iVoox Indonesia - Berita Ekonomi dan Bisnis`}</title>
        </Helmet>
        <div className='home style-politics style-rounded'>
          <Header menusManual={this.state.menusManual} />
          <main className='main oh' id='main'>
            {window.outerWidth < 480 && datasAdv['TopLock'] && (
              <div className='ads-top'>
                {datasAdv['TopLock'].type == 'media' && datasAdv['TopLock'].media && datasAdv['TopLock'].media.media_type === 'image' && (
                  <a target='_blank' href={datasAdv['TopLock'].url !== '' ? datasAdv['TopLock'].url : '#'}>
                    <img src={datasAdv['TopLock'] ? datasAdv['TopLock'].media.url : ''} style={{ width: '100%' }} />
                  </a>
                )}
                {datasAdv['TopLock'].type == 'media' && datasAdv['TopLock'].media && datasAdv['TopLock'].media.media_type === 'video' && (
                  <video width='100%' controls={false} autoPlay loop>
                    <source src={datasAdv['TopLock'].media.url} type={datasAdv['TopLock'].media.file_type} />
                    Your browser does not support the video tag.
                  </video>
                )}
                {datasAdv['TopLock'].type == 'adsense' && datasAdv['TopLock'].adsense && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: datasAdv['TopLock'].adsense
                    }}
                  ></p>
                )}
              </div>
            )}
            <TopHeader loading={loadingAdv} datas={datasAdv} />
            <header className='header d-lg-block d-none'>
              <div className='container'>
                <div className='flex-parent'>
                  <div className='flex-child row'>
                    <Link onClick={this.checkPageHome} to='/' className='logo col-md-3' id='logo-ivoox'>
                      <img className='logo__img' src={'/assets/img/logo-ivoox-1.png'} style={{ width: '90%' }} />
                    </Link>
                    <div className='text-center col-md-9'>
                      {datasAdv['TopBanner'] && (
                        <div style={{ height: 100 }}>
                          {datasAdv['TopBanner'].type == 'media' && datasAdv['TopBanner'].media && datasAdv['TopBanner'].media.media_type === 'image' && (
                            <a target='_blank' href={datasAdv['TopBanner'].url !== '' ? datasAdv['TopBanner'].url : '#'}>
                              <img
                                src={datasAdv['TopBanner'] ? datasAdv['TopBanner'].media.url : ''}
                                style={{
                                  width: '100%'
                                }}
                              />
                            </a>
                          )}
                          {datasAdv['TopBanner'].type == 'media' && datasAdv['TopBanner'].media && datasAdv['TopBanner'].media.media_type === 'video' && (
                            <video
                              width='100%'
                              style={{
                                height: 105
                              }}
                              controls={false}
                              autoPlay
                              loop
                            >
                              <source src={datasAdv['TopBanner'].media.url} type={datasAdv['TopBanner'].media.file_type} />
                              Your browser does not support the video tag.
                            </video>
                          )}
                          {datasAdv['TopBanner'].type == 'adsense' && datasAdv['TopBanner'].adsense && (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: datasAdv['TopBanner'].adsense
                              }}
                            ></p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <Navigation {...this.props} menusManual={this.state.menusManual} />
            {/* <div className="row">
                            <div className="col-md-2" />
                            <div className="col-md-8">
                                {datasAdv['TopBanner2'] && (
                                    <div style={{ marginTop: 16 }} className="col-md-10 offset-md-1">
                                        {datasAdv['TopBanner2'].type == "media" && datasAdv['TopBanner2'].media && datasAdv['TopBanner2'].media.media_type === "image" && (
                                            <a target="_blank" href={datasAdv['TopBanner2'].url !== '' ? datasAdv['TopBanner2'].url : '#'}>
                                                <img
                                                    src={
                                                        datasAdv['TopBanner2']
                                                            ? datasAdv['TopBanner2'].media.url
                                                            : ''
                                                    }
                                                    style={{ width: '100%' }}
                                                />
                                            </a>
                                        )}
                                        {datasAdv['TopBanner2'].type == "media" && datasAdv['TopBanner2'].media && datasAdv['TopBanner2'].media.media_type === "video" && (
                                            <video width="100%" style={{ height: 105 }} controls={false} autoPlay loop>
                                                <source src={datasAdv['TopBanner2'].media.url} type={datasAdv['TopBanner2'].media.file_type} />
                                                Your browser does not support the video tag.
                                                </video>
                                        )}
                                        {datasAdv['TopBanner2'].type == "adsense" && datasAdv['TopBanner2'].adsense && (
                                            <p dangerouslySetInnerHTML={{ __html: datasAdv['TopBanner2'].adsense }}></p>
                                        )}
                                    </div>
                                )}
                                <Trending />
                            </div>
                            <div className="col-md-2" />
                        </div> */}

            {window.outerWidth < 480 && datasAdv['TopBanner'] && (
              <div className='row' style={{ marginBottom: 15 }}>
                <div className='col-md-12'>
                  {datasAdv['TopBanner'] && (
                    <div style={{ maxHeight: 100 }}>
                      {datasAdv['TopBanner'].type == 'media' && datasAdv['TopBanner'].media && datasAdv['TopBanner'].media.media_type === 'image' && (
                        <a target='_blank' href={datasAdv['TopBanner'].url !== '' ? datasAdv['TopBanner'].url : '#'}>
                          <img
                            src={datasAdv['TopBanner'] ? datasAdv['TopBanner'].media.url : ''}
                            style={{
                              width: '100%'
                            }}
                          />
                        </a>
                      )}
                      {datasAdv['TopBanner'].type == 'media' && datasAdv['TopBanner'].media && datasAdv['TopBanner'].media.media_type === 'video' && (
                        <video width='100%' style={{ height: 105 }} controls={false} autoPlay loop>
                          <source src={datasAdv['TopBanner'].media.url} type={datasAdv['TopBanner'].media.file_type} />
                          Your browser does not support the video tag.
                        </video>
                      )}
                      {datasAdv['TopBanner'].type == 'adsense' && datasAdv['TopBanner'].adsense && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: datasAdv['TopBanner'].adsense
                          }}
                        ></p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className='row'>
              {datasAdv['FloatingBannerLeft'] && window.outerWidth < 480 ? null : (
                <div
                  className={this.state.floating ? 'col-md-2' : 'col-md-2 adsens-left'}
                  style={{
                    marginBottom: window.outerWidth < 480 ? 16 : 0
                  }}
                >
                  {datasAdv['FloatingBannerLeft'] && window.outerWidth < 480 ? (
                    <noscript>hide</noscript>
                  ) : (
                    <div id='getFixedLeft'>
                      {datasAdv['FloatingBannerLeft'] &&
                        datasAdv['FloatingBannerLeft'].type == 'media' &&
                        datasAdv['FloatingBannerLeft'].media &&
                        datasAdv['FloatingBannerLeft'].media.media_type === 'image' && (
                          <a target='_blank' href={datasAdv['FloatingBannerLeft'].url !== '' ? datasAdv['FloatingBannerLeft'].url : '#'}>
                            <img
                              src={datasAdv['FloatingBannerLeft'] ? datasAdv['FloatingBannerLeft'].media.url : ''}
                              className='adsens-image'
                              style={{
                                height: '100%'
                              }}
                            />
                          </a>
                        )}
                      {datasAdv['FloatingBannerLeft'] &&
                        datasAdv['FloatingBannerLeft'].type == 'media' &&
                        datasAdv['FloatingBannerLeft'].media &&
                        datasAdv['FloatingBannerLeft'].media.media_type === 'video' && (
                          <video
                            width='100%'
                            style={{
                              height: '100%'
                            }}
                            controls={false}
                            autoPlay
                            loop
                          >
                            <source src={datasAdv['FloatingBannerLeft'].media.url} type={datasAdv['FloatingBannerLeft'].media.file_type} />
                            Your browser does not support the video tag.
                          </video>
                        )}
                      {datasAdv['FloatingBannerLeft'] && datasAdv['FloatingBannerLeft'].type == 'adsense' && datasAdv['FloatingBannerLeft'].adsense && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: datasAdv['FloatingBannerLeft'].adsense
                          }}
                        ></p>
                      )}
                    </div>
                  )}
                </div>
              )}

              <div className={window.outerWidth < 480 ? 'col-md-12' : 'col-md-8'}>
                <div className='row'>
                  {/* Jadwal Imsak */}
                  {/* <div className='col-md-12'>
                    <JadwalImsak />
                  </div> */}
                  <div className='col-md-12'>
                    {datasAdv['TopBanner2'] && (
                      <div style={{ marginTop: 16 }} className='col-md-12'>
                        {datasAdv['TopBanner2'].type == 'media' && datasAdv['TopBanner2'].media && datasAdv['TopBanner2'].media.media_type === 'image' && (
                          <a target='_blank' href={datasAdv['TopBanner2'].url !== '' ? datasAdv['TopBanner2'].url : '#'}>
                            <img
                              src={datasAdv['TopBanner2'] ? datasAdv['TopBanner2'].media.url : ''}
                              style={{
                                width: '100%'
                              }}
                            />
                          </a>
                        )}
                        {datasAdv['TopBanner2'].type == 'media' && datasAdv['TopBanner2'].media && datasAdv['TopBanner2'].media.media_type === 'video' && (
                          <video
                            width='100%'
                            style={{
                              height: 105
                            }}
                            controls={false}
                            autoPlay
                            loop
                          >
                            <source src={datasAdv['TopBanner2'].media.url} type={datasAdv['TopBanner2'].media.file_type} />
                            Your browser does not support the video tag.
                          </video>
                        )}
                        {datasAdv['TopBanner2'].type == 'adsense' && datasAdv['TopBanner2'].adsense && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: datasAdv['TopBanner2'].adsense
                            }}
                          ></p>
                        )}
                      </div>
                    )}
                    <Trending />
                    <TagsHeader />
                  </div>
                  <div className='col-md-12'>
                    <Covid19 />
                  </div>
                </div>

                <Switch>
                  <Route
                    exact
                    path='/'
                    render={(props) => <Home name='ivoox.id' loadingHeadline={loadingHeadline} datasHeadlineGroup={datasHeadlineGroup} {...this.props} />}
                  />
                  <Route path='/contactUs' component={withPageView(ContactUs)} />
                  <Route path='/notFound' component={withPageView(NotFound)} />
                  <Route path='/auth' component={withPageView(Login)} />
                  <Route path='/register' component={withPageView(Register)} />
                  <Route path='/forgotPassword' component={withPageView(ForgotPassword)} />
                  <Route path='/resetPassword/:token?' component={withPageView(ResetPassword)} />
                  <Route path='/about' component={withPageView(About)} />
                  <Route path='/careerForm' component={withPageView(CareerForm)} />
                  <Route path='/beriklan' component={withPageView(Beriklan)} />
                  <Route path='/privacyPolicy' component={withPageView(PrivacyPolicy)} />
                  <Route path='/redaksi' component={withPageView(redaksi)} />
                  <Route path='/alamat-redaksi' component={withPageView(AlamatRedaksi)} />
                  <Route path='/sop-perlindungan-jurnalis' component={withPageView(SopPerlindunganJurnalis)} />
                  <Route path='/pedoman-pemberitaan-media-siber' component={withPageView(PedomanPemberitaanMediaSiber)} />
                  <Route path='/disclaimer' component={withPageView(Disclaimer)} />
                  <Route path='/search' component={withPageView(Search)} />
                  <Route path='/latestNews' component={withPageView(ArticlesLatest)} />
                  <Route path='/category/:slug?' component={withPageView(Categories)} />
                  <Route path='/tag/:slug?' component={withPageView(Tags)} />
                  <Route path='/authorPost/:username?' component={withPageView(Author)} />
                  <Route path='/polling/all' component={withPageView(Polling)} />
                  <Route path='/polling/:id?/:slug?' component={withPageView(PollingVote)} />
                  {/* start admin router */}
                  <PrivateRoute path='/admin/dashboard' component={Dashboard} />
                  <PrivateRoute path='/admin/media' component={Media} />
                  <PrivateRoute path='/admin/category' component={Category} />
                  <PrivateRoute path='/admin/tag' component={Tag} />
                  <PrivateRoute path='/admin/ads' component={Ads} />
                  <PrivateRoute path='/admin/polling/add' component={PollingCreate} />
                  <PrivateRoute path='/admin/polling/:id' component={PollingEdit} />
                  <PrivateRoute path='/admin/polling' component={PollingDashboard} />
                  <PrivateRoute path='/admin/users/add' component={UserCreate} />
                  <PrivateRoute path='/admin/users/:id' component={UserEdit} />
                  <PrivateRoute path='/admin/users' component={User} />
                  <PrivateRoute path='/admin/roles' component={Role} />
                  <PrivateRoute path='/admin/careerPosition' component={CareerPosition} />
                  <PrivateRoute path='/admin/comments' component={Comment} />
                  <PrivateRoute path='/admin/activityLogs/:id' component={DetailActivityLog} />
                  <PrivateRoute path='/admin/activityLogs' component={ActivityLog} />
                  <PrivateRoute path='/admin/post/preview/:postId' component={PreviewPost} />
                  <PrivateRoute path='/admin/post/add/:for' component={PostCreate} />
                  <PrivateRoute path='/admin/post/:id/:for' component={PostEdit} />
                  <PrivateRoute path='/admin/post' component={PostDashboard} />
                  <PrivateRoute path='/admin/nitizenJurnalism' component={NitizenJurnalism} />
                  <PrivateRoute path='/admin/profile' component={Profile} />
                  <PrivateRoute path='/admin/report/posts' component={ReportPost} />
                  <PrivateRoute path='/admin/report/contacts' component={ReportContact} />
                  <PrivateRoute path='/admin/report/careers' component={ReportCareer} />
                  {/* end admin router */}
                  <PrivateRoute path='/test/:slug' component={withPageView(Test)} />
                  <Route path='/:slug?' component={withPageView(Articles)} />
                  <Route component={withPageView(NotFound)} />
                </Switch>
              </div>

              {datasAdv['FloatingBannerRight'] && window.outerWidth < 480 ? null : (
                <div
                  className={this.state.floating ? 'col-md-2' : 'col-md-2 adsens-right'}
                  style={{
                    marginBottom: window.outerWidth < 480 ? 16 : 0
                  }}
                >
                  {datasAdv['FloatingBannerRight'] && window.outerWidth < 480 ? (
                    <noscript>hide</noscript>
                  ) : (
                    <div id='getFixedRight'>
                      {datasAdv['FloatingBannerRight'] &&
                        datasAdv['FloatingBannerRight'].type == 'media' &&
                        datasAdv['FloatingBannerRight'].media &&
                        datasAdv['FloatingBannerRight'].media.media_type === 'image' && (
                          <a target='_blank' href={datasAdv['FloatingBannerRight'].url !== '' ? datasAdv['FloatingBannerRight'].url : '#'}>
                            <img src={datasAdv['FloatingBannerRight'] ? datasAdv['FloatingBannerRight'].media.url : ''} className='adsens-image' />
                          </a>
                        )}
                      {datasAdv['FloatingBannerRight'] &&
                        datasAdv['FloatingBannerRight'].type == 'media' &&
                        datasAdv['FloatingBannerRight'].media &&
                        datasAdv['FloatingBannerRight'].media.media_type === 'video' && (
                          <video width='100%' controls={false} autoPlay loop>
                            <source src={datasAdv['FloatingBannerRight'].media.url} type={datasAdv['FloatingBannerRight'].media.file_type} />
                            Your browser does not support the video tag.
                          </video>
                        )}
                      {datasAdv['FloatingBannerRight'] && datasAdv['FloatingBannerRight'].type == 'adsense' && datasAdv['FloatingBannerRight'].adsense && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: datasAdv['FloatingBannerRight'].adsense
                          }}
                        ></p>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>

            {window.outerWidth < 480 && (
              <aside className='col-lg-3 sidebar order-lg-3'>
                <LoadingOverlay active={loadingHeadline} spinner text='Loading your content...'>
                  <aside className='widget widget-popular-posts' style={{ padding: 0, border: 0 }}>
                    {/* <h4 className="widget-title">Weekly Popular</h4> */}
                    <iframe
                      id='IDRRates'
                      src='https://api.stockdio.com/visualization/financial/charts/v1/QuoteBoard?app-key=31B5314D67A44ABE9C2B5B0BFAA168E7&amp;stockExchange=FOREX&amp;symbols=USD/IDR;EUR/IDR;AUD/IDR;SGD/IDR;HKD/IDR;CNY/IDR;GBP/IDR&amp;includeLogo=false&amp;includeSymbol=false&amp;palette=Financial-Light&amp;title=IDR%20Exchange%20Rates&amp;onload=IDRRates&amp;displayTargetCurrency=false'
                      width='100%'
                      height='267'
                      frameBorder='0'
                      scrolling='no'
                      style={{ height: 267 }}
                    />
                    <iframe
                      id='topGainers'
                      src='https://api.stockdio.com/visualization/financial/charts/v1/QuoteBoard?app-key=31B5314D67A44ABE9C2B5B0BFAA168E7&amp;stockExchange=IDX&amp;presetList=top_gainers&amp;limit=10&amp;palette=Financial-Light&amp;title=Top%20Gainers&amp;intraday=false&amp;onload=topGainers&amp;includeLogo=false&amp;includeCompany=false&amp;allowSort=false&amp;includeChange=true&amp;includePercentChange=true&amp;fontSize=9'
                      width='100%'
                      height='auto'
                      frameBorder='0'
                      scrolling='no'
                      style={{
                        height: 275,
                        marginTop: 16
                      }}
                    />
                    <iframe
                      id='topLosers'
                      src='https://api.stockdio.com/visualization/financial/charts/v1/QuoteBoard?app-key=31B5314D67A44ABE9C2B5B0BFAA168E7&amp;stockExchange=IDX&amp;presetList=top_losers&amp;limit=10&amp;palette=Financial-Light&amp;title=Top%20Losers&amp;intraday=false&amp;onload=topLosers&amp;includeLogo=false&amp;includeCompany=false&amp;allowSort=false&amp;includeChange=true&amp;includePercentChange=true&amp;fontSize=9'
                      width='100%'
                      height='auto'
                      frameBorder='0'
                      scrolling='no'
                      style={{
                        height: 275,
                        marginTop: 16
                      }}
                    />
                  </aside>
                </LoadingOverlay>
              </aside>
            )}

            {window.outerWidth < 480 && (
              <aside className='col-lg-3 sidebar order-lg-1'>
                <LoadingOverlay active={loadingHeadline} spinner text='Loading your content...'>
                  <aside className='widget widget-headlines'>
                    <h4 className='widget-title'>More Headlines</h4>
                    <div id='owl-headlines' className='owl-carousel owl-theme'>
                      {datasHeadlineGroup.map((headline, headlineIndex) => (
                        <div className='owl-item' key={headlineIndex}>
                          <ul className='post-list-small post-list-small--1'>
                            {headline.map((headlineItem, headlineItemIndex) => (
                              <li className='post-list-small__item' key={headlineItemIndex}>
                                <article className='post-list-small__entry clearfix'>
                                  <div className='post-list-small__body'>
                                    <h3 className='post-list-small__entry-title'>
                                      <Link
                                        to={{
                                          pathname: `/${headlineItem.url}`,
                                          search: `?tag_from=${headlineItem.tagables[0] ? headlineItem.tagables[0].tag.slug : 'unknown'}`
                                        }}
                                      >
                                        {headlineItem.title}
                                      </Link>
                                    </h3>
                                  </div>
                                </article>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                    <div className='owl-custom-nav text-center'>
                      <button className='owl-custom-nav__btn owl-custom-nav__btn--prev' aria-label='previous slide'>
                        <i className='ui-arrow-left' />
                        <span className='owl-custom-nav__label'>Prev</span>
                      </button>
                      <button className='owl-custom-nav__btn owl-custom-nav__btn--next' aria-label='next slide'>
                        <span className='owl-custom-nav__label'>Next</span>
                        <i className='ui-arrow-right' />
                      </button>
                    </div>
                  </aside>
                </LoadingOverlay>
              </aside>
            )}

            <Footer loading={loadingAdv} datas={datasAdv} />
            {/* <div
                            id="getFixedBottom"
                            className={
                                window.outerWidth < 480
                                    ? `ads-bottom-static`
                                    : 'ads-bottom'
                            }
                        >
                            <a target="_blank" href="">
                                <img
                                    src="https://ivooxs3.s3.ap-southeast-1.amazonaws.com/media_public/1566290306843_upload_36d2468c950001049e98aab00d5fd920.png"
                                    style={{ width: '100%' }}
                                />
                            </a>
                        </div> */}
            {datasAdv['BottomLock'] && (
              <div id='getFixedBottom' className={window.outerWidth < 480 ? `ads-bottom-static` : 'ads-bottom'}>
                {datasAdv['BottomLock'].type == 'media' && datasAdv['BottomLock'].media && datasAdv['BottomLock'].media.media_type === 'image' && (
                  <a target='_blank' href={datasAdv['BottomLock'].url !== '' ? datasAdv['BottomLock'].url : '#'}>
                    <img src={datasAdv['BottomLock'] ? datasAdv['BottomLock'].media.url : ''} style={{ width: '100%' }} />
                  </a>
                )}
                {datasAdv['BottomLock'].type == 'media' && datasAdv['BottomLock'].media && datasAdv['BottomLock'].media.media_type === 'video' && (
                  <video width='100%' style={{ width: '100%' }} controls={false} autoPlay loop>
                    <source src={datasAdv['BottomLock'].media.url} type={datasAdv['BottomLock'].media.file_type} />
                    Your browser does not support the video tag.
                  </video>
                )}
                {datasAdv['BottomLock'].type == 'adsense' && datasAdv['BottomLock'].adsense && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: datasAdv['BottomLock'].adsense
                    }}
                  ></p>
                )}
              </div>
            )}

            <div id='back-to-top'>
              <a href='#top' aria-label='Go to top'>
                <i className='ui-arrow-up' />
              </a>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
