import React, { Component } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import Reaptcha from 'reaptcha'

import Api from '../../../services/api'
import ModalMedia from "../../../components/modal/ModalMediaForAds";
import Toast from '../../../components/Toast'
import { apiUrl, keyToken, sitekeyReaptcha } from "../../../config";
import { IsSSR } from "../../../helpers/isSSR";
import { getStorageItem, removeStorageItem } from "../../../helpers/storage";

class AdsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            file: null,
            selectedImagesModal: [],
            images: [],
            imagePagination: {
                total_found: 1,
                limit: 9,
                current_page: 1,
                total_page: 1
            },
            errors: {},
            verified: false,
            keyword: "",
        };
        this.isSSR = IsSSR();
        this.token = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.toggleAddImagePostModal = this.toggleAddImagePostModal.bind(this);
        this.getImagePosts = this.getImagePosts.bind(this);
        this.setSelectedImagesModal = this.setSelectedImagesModal.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.onChangeKeyword = this.onChangeKeyword.bind(this);
    }

    componentDidMount() {
        if (['admin', 'editor', 'author'].indexOf(this.props.isAs) > -1) {
            this.setState({ verified: true });
        }
    }

    onChangeKeyword(e) {
        const { name, value } = e.target;
        this.setState({ keyword: value })
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.setParameterErrors({});
        if (
            this.props.formData.type &&
            this.props.formData.type === "adsense" &&
            !this.props.formData.adsense
        ) {
            let errors = {};
            let adsense = {};
            adsense.message = "The field is mandatory.";
            errors.adsense = adsense;
            this.props.setParameterErrors(errors);
            if (this.props.editFormStatus) { Toast("Fail to update ads!") }
            else { Toast("Fail to create ad!") }
        } else if (
            this.props.formData.type &&
            this.props.formData.type === "media" &&
            this.props.selectedImages.length < 1
        ) {
            let errors = {};
            let media_id = {};
            media_id.message = "The media field is mandatory.";
            errors.media_id = media_id;
            this.props.setParameterErrors(errors);
            if (this.props.editFormStatus) { Toast("Fail to update ad!") }
            else { Toast("Fail to create ad!") }
        } else {
            this.props.setLoadingContainer(true);
            if (this.props.editFormStatus) {
                this.doUpdateCategory(this.props.selectedImages.join(","));
            } else {
                this.doCreateCategory(this.props.selectedImages.join(","));
            }
        }
    }

    getFileExtension3(filename) {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
    }

    handleFileUpload(e) {
        const file = e.target.files[0];
        let mediaType = "";

        if (file) {
            let fileExt = this.getFileExtension3(file.name);
            if (["avi", "mp4", "mpg", "3gp", "webm", "mkv", "gifv", "wmv"].includes(fileExt)) {
                mediaType = "video";
            } else {
                mediaType = "image";
            }
            this.setState({ loading: true });
            const formData = new FormData();
            let arrayName = file.name.split('.');
            arrayName.pop();
            let nameImage = arrayName.join(' ');

            formData.append("media", file);
            formData.append("meta_tag", nameImage.substring(0, 80));
            formData.append("media_type", mediaType);
            formData.append("dimension", "all");
            formData.append("caption", this.props.caption)
            formData.append("is_not_compress", 1)
            const route = `/media/create`;
            fetch(`${apiUrl}` + route, {
                method: 'POST',
                headers: new Headers({
                    'x-access-token': this.token
                }),
                body: formData
            })
                .then(res => res.json()).then(response => {
                    this.setState({ loading: false });
                    if (response.status && response.status === "success") {
                        let images = [];
                        images[0] = response.data.id;
                        this.props.addImagePost(images, response.data.url, response.data.media_type, response.data.file_type, response.data.meta_tag);
                    } else {
                        this.checkError(response);
                    }
                })
                .catch(err => {
                    this.setState({ loading: false });
                    Toast('Error internet connection');
                });
        }
    }

    doCreateCategory(media_id) {
        const params = {
            name: this.props.formData.name,
            position: this.props.formData.position,
            type: this.props.formData.type,
            media_id: media_id,
            adsense: this.props.formData.adsense,
            url: this.props.formData.url,
        };
        const route = `/ad/create`;
        Api.post(route, params, this.token)
            .then(response => {
                this.props.setLoadingContainer(false);
                if (response.status && response.status === 'success') {
                    Toast(response.message, 'success');
                    setTimeout(function () {
                        window.location.reload()
                    }, 600)
                } else {
                    this.checkError(response)
                }
            })
            .catch(err => {
                this.props.setLoadingContainer(false);
                this.checkError(err)
            })
    }

    doUpdateCategory(media_id) {
        let params = {
            name: this.props.formData.name,
            position: this.props.formData.position,
            type: this.props.formData.type,
            media_id: this.props.formData.media_id,
            adsense: this.props.formData.adsense,
            url: this.props.formData.url,
        };
        if (media_id !== null) params['media_id'] = media_id;
        const route = `/ad/update/` + this.props.editFormId;
        Api.put(route, params, this.token)
            .then(response => {
                this.props.setLoadingContainer(false);
                if (response.status && response.status === 'success') {
                    Toast(response.message, 'success')
                } else {
                    this.checkError(response)
                }
            })
            .catch(err => {
                this.props.setLoadingContainer(false);
                this.checkError(err)
            })
    }

    checkError(response) {
        if (response.status && response.status === 'failed') {
            if (response.error) {
                this.props.setParameterErrors(response.error);
                var msg = response.message;
                if (response.message === "Fail to Authentication." || response.message == "No token provided.") {
                    msg = 'Token Expired, Please login again!';
                    removeStorageItem(keyToken).then(r => console.log('remove token'));
                    this.props.history.push(`/auth`);
                    setTimeout(() => {
                        window.location.reload()
                    }, 600);
                }
                Toast(msg);
            } else {
                Toast(response.message)
            }
        } else {
            Toast('Error internet connection')
        }
    }

    toggleAddImagePostModal() {
        if (!this.state.addImagePostModal) {
            this.getImagePosts();
        }
        let selectedImages = this.props.selectedImages;
        this.setState({
            addImagePostModal: !this.state.addImagePostModal,
            selectedImagesModal: selectedImages
        });
    }

    setSelectedImagesModal(selectedImagesModal) {
        this.setState({ selectedImagesModal });
    }

    getImagePosts(page = 1, limit = 9) {
        this.setState({ loadingImage: true });
        const route = `/media/getImageAndVideo?page=` + page + `&limit=` + limit + `&keyword=` + this.state.keyword;
        Api.get(route, this.token)
            .then(response => {
                if (response.status && response.status === "success") {
                    this.setState({ images: response.data, imagePagination: response.pagination, loadingImage: false });
                }
            })
            .catch(() => {
                this.setState({ loadingImage: false });
                Toast('Error internet connection');
            })
    }

    cancel(e) {
        e.preventDefault();
        this.props.refreshEditData()
    }

    onVerify = recaptchaResponse => {
        this.setState({
            verified: true
        })
    };

    render() {
        var positionLists = [
            { id: 1, key: 'TopBanner2', name: 'Top Banner (970x90)' },
            { id: 2, key: 'BottomBanner', name: 'Bottom Banner (848 x 105)' },
            {
                id: 3,
                key: 'FloatingBannerLeft',
                name: 'Floating Banner Left (160 x 600)'
            },
            {
                id: 4,
                key: 'FloatingBannerRight',
                name: 'Floating Banner Right (160 x 600)'
            },
            { id: 5, key: 'TopLock', name: 'Top Lock (1366 x 60)' },
            {
                id: 6,
                key: 'TopBanner',
                name: 'Header  (970x90)'
            },
            { id: 7, key: 'BottomLock', name: 'Bottom Lock (1366 x 60)' },
            { id: 8, key: 'Holistick2', name: 'Holistick 2 (263 x 263)' },
            { id: 9, key: 'Holistick1', name: 'Holistick 1 (263 x 263)' },
            // { id: 10, key: 'Header', name: 'Header (848 x 105)' },
            { id: 11, key: 'RightKanal', name: 'Right Kanal (400 x 400)' },
            { id: 12, key: 'MiddleBanner', name: 'Middle Banner (728x90)' },
            { id: 13, key: 'InNewsRight', name: 'In News Right (300x600)' },
            { id: 14, key: 'Popup', name: 'Popup' }
        ];
        positionLists.sort(function (a, b) { return (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0); });

        // Header, Top Banner, Floating Banner Left, Floating Banner Right, Top Lock, Bottom Lock, Bottom Banner
        // Middle Banner, In News Right

        const typeLists = [
            { id: 1, key: 'adsense', name: 'Adsense' },
            { id: 2, key: 'media', name: 'Media' }
        ];
        let lists = positionLists.map(item => {
            return (
                <option key={item.id} value={item.key}>
                    {item.name}
                </option>
            )
        });
        lists.push(
            <option key={0} value="">
                -- Choose --
			</option>
        );
        let types = typeLists.map(item => {
            return (
                <option key={item.id} value={item.key}>
                    {item.name}
                </option>
            )
        });
        types.push(
            <option key={0} value="">
                -- Choose --
			</option>
        );

        let headerContent;
        let buttonContent;
        if (this.props.editFormStatus) {
            headerContent = (
                <h4 className="heading-title">
                    Edit Ads Id {this.props.editFormId}
                </h4>
            );
            buttonContent = (
                <div className="form-group">
                    <button
                        key="category-btn-cancel"
                        type="button"
                        onClick={this.cancel}
                        className="btn btn-md btn-button mr-3"
                    >
                        Cancel
					</button>
                    <button
                        disabled={!this.state.verified || this.state.loading}
                        key="category-btn-edit"
                        className="btn btn-md btn-color btn-button"
                    >
                        Save
					</button>
                </div>
            )
        } else {
            headerContent = <h4 className="heading-title">Add New Ads</h4>;
            buttonContent = (

                
                <div className="form-group">
                    <button
                        disabled={!this.state.verified || this.state.loading}
                        key="category-btn-add"
                        className="btn btn-md btn-color btn-button"
                    >
                        Add Ads
					</button>
                </div>
            )
        }
        let imageContainer;
        if (this.props.selectedImages.length > 1) {
            imageContainer = (
                <div style={{ paddingBottom: "10px" }}>
                    {this.props.selectedImages.length} Images
				</div>
            );
        } else if (this.props.selectedImages.length === 1) {
            if (this.props.mediaType === "image") {
                imageContainer = (
                    <div style={{ paddingBottom: "10px" }}>
                        <div
                            style={{
                                height: "150px", width: "100%", backgroundImage: `url(${this.props.imageText}`,
                                backgroundRepeat: "no-repeat", backgroundSize: "cover"
                            }}
                            title={this.props.meta_tag}
                        ></div>
                    </div>
                );
            } else if (this.props.mediaType === "video") {
                imageContainer = (
                    <div style={{ paddingBottom: "10px" }}>
                        <div
                            style={{ height: "150px", width: "100%", textAlign: "center" }}
                            title={this.props.meta_tag}
                        >
                            <video width="100%" controls>
                                <source src={this.props.imageText} type={this.props.file_type} />
                                Your browser does not support the video tag.
							</video>
                        </div>
                    </div>
                );
            }
        }
        return (
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text="Please Wait..."
            >
                <ModalMedia
                    type={"post"}
                    images={this.state.images}
                    selectedImagesModal={this.state.selectedImagesModal}
                    selectedImages={this.props.selectedImages}
                    pagination={this.state.imagePagination}
                    addImagePostModal={this.state.addImagePostModal}
                    toggleAddImagePostModal={this.toggleAddImagePostModal}
                    loadingImage={this.state.loadingImage}
                    addImagePost={this.props.addImagePost}
                    refreshData={this.getImagePosts}
                    setSelectedImagesModal={this.setSelectedImagesModal}
                    keyword={this.state.keyword}
                    onChangeKeyword={this.onChangeKeyword}
                    token={this.token}
                />
                <div className="row">
                    <div className="col-12">

                    <div className="download-guidance-ivoox">
                        <a class="btn btn btn-md btn-color btn-file mb-4" href="/assets/pdf/ivoox-ads-guide.pdf" download>Download Guidance Ads</a>
                    </div>
                        {headerContent}
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">
                                    Name <span className="text-red">*</span>
                                </label>
                                <input
                                    name="name"
                                    placeholder=""
                                    id="name"
                                    type="text"
                                    onChange={this.props.onChangeForm}
                                    value={this.props.formData.name}
                                    style={{
                                        marginBottom: this.props.errors.name
                                            ? 0
                                            : 10
                                    }}
                                />
                                {this.props.errors.name &&
                                    this.props.errors.name.message && (
                                        <div
                                            className="help-block"
                                            style={{
                                                backgroundColor: 'red',
                                                paddingLeft: 10,
                                                paddingRight: 10
                                            }}
                                        >
                                            <small style={{ color: 'white' }}>
                                                {this.props.errors.name.message}
                                            </small>
                                        </div>
                                    )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="position">
                                    Position <span className="text-red">*</span>
                                </label>
                                <select
                                    name="position"
                                    placeholder=""
                                    id="position"
                                    onChange={this.props.onChangeForm}
                                    value={this.props.formData.position || ''}
                                    style={{
                                        marginBottom: this.props.errors.position
                                            ? 0
                                            : 10
                                    }}
                                >
                                    {lists}
                                </select>
                                {this.props.errors.position &&
                                    this.props.errors.position.message && (
                                        <div
                                            className="help-block"
                                            style={{
                                                backgroundColor: 'red',
                                                paddingLeft: 10,
                                                paddingRight: 10
                                            }}
                                        >
                                            <small style={{ color: 'white' }}>
                                                {
                                                    this.props.errors.position
                                                        .message
                                                }
                                            </small>
                                        </div>
                                    )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="type">
                                    Type <span className="text-red">*</span>
                                </label>
                                <select
                                    name="type"
                                    placeholder=""
                                    id="type"
                                    onChange={this.props.onChangeForm}
                                    value={this.props.formData.type || ''}
                                    style={{
                                        marginBottom: this.props.errors.type
                                            ? 0
                                            : 10
                                    }}
                                >
                                    {types}
                                </select>
                                {this.props.errors.type &&
                                    this.props.errors.type.message && (
                                        <div
                                            className="help-block"
                                            style={{
                                                backgroundColor: 'red',
                                                paddingLeft: 10,
                                                paddingRight: 10
                                            }}
                                        >
                                            <small style={{ color: 'white' }}>
                                                {this.props.errors.type.message}
                                            </small>
                                        </div>
                                    )}
                            </div>
                            {this.props.formData.type !== "adsense" && (
                                <div className="form-group">
                                    <label htmlFor="media_id">
                                        Image <span className="text-red">*</span>
                                    </label>
                                    {imageContainer}
                                    <div>
                                        <div className="form-group">
                                            <label htmlFor="caption">Caption(Untuk Upload)</label>
                                            <input type="text" placeholder="Caption" name="caption" value={this.props.caption} onChange={this.props.onChangeCaption}
                                                style={{ marginBottom: "0px" }}
                                            />
                                            {this.state.errors.caption && this.state.errors.caption.message && (
                                                <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                                    <small style={{ color: 'white' }}>{this.state.errors.caption.message}</small>
                                                </div>
                                            )}
                                        </div>
                                        <label className="btn btn btn-md btn-color btn-file" onClick={this.toggleAddImagePostModal} style={{ marginRight: "10px" }}>
                                            Browse
										</label>
                                        <label className="btn btn btn-md btn-color btn-file">
                                            Upload
											<input
                                                type="file"
                                                onChange={this.handleFileUpload}
                                            />
                                        </label>
                                    </div>
                                    <p>
                                        The image must be a file of type: jpg,
										jpeg, png, ico
									</p>
                                    {this.props.errors.media_id &&
                                        this.props.errors.media_id.message && (
                                            <div
                                                className="help-block"
                                                style={{
                                                    backgroundColor: 'red',
                                                    paddingLeft: 10,
                                                    paddingRight: 10
                                                }}
                                            >
                                                <small style={{ color: 'white' }}>
                                                    {
                                                        this.props.errors.media_id
                                                            .message
                                                    }
                                                </small>
                                            </div>
                                        )}
                                </div>
                            )}
                            {this.props.formData.type === "adsense" && (
                                <div className="row form-group">
                                    <div className="col-lg-12 col-12">
                                        <label htmlFor="adsense">Adsense</label>
                                        <textarea name="adsense" rows="7" value={this.props.formData.adsense || ''} onChange={this.props.onChangeForm}></textarea>
                                    </div>
                                    {this.props.errors.adsense && this.props.errors.adsense.message && (
                                        <div className="help-block col-12" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                            <small style={{ color: 'white' }}>{this.props.errors.adsense.message}</small>
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="row form-group">
                                <div className="col-lg-12 col-12">
                                    <label htmlFor="url">Link URL</label>
                                    <textarea name="url" rows="3" value={this.props.formData.url || ''} onChange={this.props.onChangeForm}></textarea>
                                </div>
                                {this.props.errors.url && this.props.errors.url.message && (
                                    <div className="help-block col-12" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                        <small style={{ color: 'white' }}>{this.props.errors.url.message}</small>
                                    </div>
                                )}
                            </div>
                            <hr />
                            <div style={{ marginBottom: 16 }}>
                                {(['admin', 'editor', 'author'].indexOf(this.props.isAs) <= -1) &&
                                    <Reaptcha
                                        sitekey={sitekeyReaptcha}
                                        onVerify={this.onVerify}
                                    />
                                }
                            </div>
                            {buttonContent}
                        </form>
                    </div>
                </div>
            </LoadingOverlay>
        )
    }
}

export default AdsForm
